import React from 'react'

const MedicalServicesIcon = (props: any) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
      fill="#112971"
    />
    <path
      d="M16.8741 8.73701H15.5351V7.31589C15.5351 6.29995 14.7335 5.47382 13.7148 5.47382H10.2855C9.26626 5.47382 8.46488 6.29995 8.46488 7.31589V8.73701H7.12561C6.213 8.73701 5.47362 9.44159 5.47362 10.3515V16.6078C5.47362 17.5174 6.213 18.2547 7.12561 18.2547H16.8741C17.7865 18.2547 18.5264 17.5174 18.5264 16.6076V10.3515C18.5264 9.44159 17.7865 8.73701 16.8741 8.73701ZM9.55261 7.31589C9.55261 6.93546 9.90394 6.56155 10.2855 6.56155H13.7148C14.0952 6.56155 14.4474 6.93546 14.4474 7.31589V8.73701H9.55261V7.31589ZM14.9913 14.7195H13.0877V16.6231H10.9123V14.7195H9.00874V12.5441H10.9123V10.6405H13.0877V12.5441H14.9913V14.7195Z"
      fill="white"
    />
  </svg>
)

export default MedicalServicesIcon
