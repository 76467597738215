import { useEffect, useState } from 'react'

type UseActiveTabTrackerItem = { selector: string }

export default function useActiveTabTracker(items: UseActiveTabTrackerItem[]): string {
  const [activeElement, setActiveElement] = useState<string>('')

  useEffect(() => {
    const handleScroll = () => {
      items.some((item) => {
        const target = document.querySelector(`#${item.selector}`)
        if (target) {
          const rect = target.getBoundingClientRect()
          if (rect.top >= 0 && rect.top < window.innerHeight / 2) {
            setActiveElement(item.selector)
            return true
          }
        }
        return false
      })
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [items])

  return activeElement
}
