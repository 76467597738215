import { useRouter } from 'next/router'
import { useContext } from 'react'
import { AppDataContext } from '../providers/AppData'

const useForLocales = ['en-ae', 'ar-ae']

export const useHrefLang = () => {
  const { locale } = useContext(AppDataContext)
  const router = useRouter()
  if (useForLocales.includes(locale)) {
    return useForLocales.map((useForLocale) => ({
      rel: 'alternate',
      href: `https://www.doctify.com/${useForLocale}${router.asPath}`,
      hreflang: useForLocale
    }))
  }
  return undefined
}
