import { useMemo } from 'react'
import { Breadcrumb } from '../types/breadcrumbs'
import getBreadcrumbsSchema from '../utils/schema/breadcrumbs'

export default function useBreadCrumbsSchema(existingSchema?: string, breadcrumbs?: Breadcrumb[]) {
  return useMemo(() => {
    if (existingSchema) {
      const schemaObj = JSON.parse(existingSchema)
      if (breadcrumbs && breadcrumbs.length) {
        schemaObj.push(getBreadcrumbsSchema(breadcrumbs))
      }
      return JSON.stringify(schemaObj)
    }

    return JSON.stringify(getBreadcrumbsSchema(breadcrumbs))
  }, [existingSchema, breadcrumbs])
}
