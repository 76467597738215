import React, { useContext } from 'react'
import { Grid, Typography, styledComponent, Tooltip, Box, Link, InfoIcon } from '@hermes/web-components'
import { useRouter } from 'next/router'
import useTranslateMessage from '../../../hooks/useTranslateMessage'
import { AppDataContext } from '../../../providers/AppData'
import { doctifyProviderDomain, endpoint, pathMap } from '../../../constants/profile'
import { handleAddCookieCTA } from '../../../utils/cookie'

const AlertRoot = styledComponent(Grid, {
  shouldForwardProp: (prop: string) => prop !== 'bordered'
})<{ bordered?: boolean }>(({ theme, bordered }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(1.5, 2),
  borderRadius: 4,
  border: bordered ? `1px solid ${theme.palette.primary.main}` : 'none'
}))

type BasicProfileAlertPropsType = {
  name: string
  id: number
  slug: string
  plan: string
}

export const BasicProfileAlert = ({ name, id, slug, plan }: BasicProfileAlertPropsType) => {
  const { locale } = useContext(AppDataContext)
  const translate = useTranslateMessage()
  const router = useRouter()

  const isMarketingEmail = router.query?.utm_source === 'marketingemail'
  const link = isMarketingEmail
    ? `/${locale}${router.asPath.split('?')[0]}/${
        locale === 'de' || locale === 'de-at' ? 'beanspruchen-sie-ihr-profil' : 'claim-your-profile'
      }`
    : doctifyProviderDomain + pathMap[locale] + endpoint
  return (
    <AlertRoot container justifyContent="space-between">
      <Grid item>
        <Typography variant="body2">{translate('are_you', { name })}</Typography>
      </Grid>
      <Grid item>
        <Link
          variant="caption"
          color="primary.main"
          href={link}
          underline="none"
          onClick={() => handleAddCookieCTA({ id, slug, type: plan })}
        >
          {translate(isMarketingEmail ? 'claim-your-profile' : 'manage_your_profile')}
        </Link>
      </Grid>
    </AlertRoot>
  )
}

export const ReviewsAlert = ({
  name,
  bordered,
  isProOrExpertPro,
  isEntry
}: {
  name: string
  bordered?: boolean
  isProOrExpertPro?: boolean
  isEntry?: boolean
}) => {
  const translate = useTranslateMessage()
  return (
    <AlertRoot container justifyContent="space-between" bordered={bordered}>
      <Grid item display="flex" alignItems="center">
        <Typography variant="body2">
          {translate(isProOrExpertPro ? 'reviews.not_collecting_reviews_yet' : 'reviews.not_collecting_reviews', {
            name
          })}
        </Typography>
      </Grid>
      {!isProOrExpertPro && !isEntry && (
        <Grid item>
          <Tooltip enterTouchDelay={0} title={translate('reviews.not_collecting_reviews.tooltip', { name })}>
            <Box sx={{ cursor: 'pointer' }} color="primary.main" display="flex" alignItems="center">
              <Typography variant="body2" fontWeight={500}>
                {translate('why')}
              </Typography>
              <InfoIcon fontSize="small" sx={{ ml: 0.5 }} />
            </Box>
          </Tooltip>
        </Grid>
      )}
    </AlertRoot>
  )
}
