import { Tooltip, tooltipClasses, styled, type TooltipProps } from '@mui/material'
import React, { FC, useState } from 'react'

const CustomTooltip: FC<TooltipProps> = ({ children, ...props }) => {
  const [open, setOpen] = useState(false)

  const show = () => setOpen(true)
  const hide = () => setOpen(false)

  return (
    <Tooltip {...props} onMouseEnter={show} onClick={show} onMouseLeave={hide} open={open}>
      {children}
    </Tooltip>
  )
}

const WhiteTooltip = styled(
  ({ className, ...props }: TooltipProps) => <CustomTooltip {...props} classes={{ popper: className }} />,
  {
    shouldForwardProp: (prop) => prop !== 'isRtl'
  }
)<{ isRtl?: boolean }>(({ isRtl }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    width: '220px',
    height: '100px',
    backgroundColor: '#fff',
    color: '#2C3341',
    border: '1px solid #AABDF3',
    boxShadow: '0px 7px 8px 0px #0203030A',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    borderRadius: '5px',
    fontSize: '12px',
    fontWeight: 400,
    top: 0,
    padding: '15px 20px',
    zIndex: 99999,
    textAlign: 'center',
    '&:after, &:before': {
      top: '-10px',
      zIndex: -1,
      left: '50%',
      transform: 'translateX(-50%)',
      content: '""',
      position: 'absolute',
      pointerEvents: 'none'
    },
    '&:after': {
      backgroundColor: '#fff',
      height: '20px',
      width: '33px',
      marginTop: '10px'
    },
    '&:before': {
      backgroundColor: '#fff',
      top: isRtl ? '-22px' : '1px',
      borderRadius: '3px',
      transform: 'rotate(45deg) translateX(-70%)',
      border: '1px solid #AABDF3',
      height: '24px',
      width: '24px'
    }
  }
}))

export { CustomTooltip as Tooltip, WhiteTooltip, TooltipProps }
