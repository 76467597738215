import React from 'react'

const VideocamGradient = ({ width, height }: { width?: number; height?: number }) => (
  <svg width={width || 32} height={height || 32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill="#2B59E0" />
    <rect width="32" height="32" rx="16" fill="url(#paint0_linear_3572_830)" fillOpacity="0.7" />
    <path
      d="M18.1429 20.2858H10.8571C10.6298 20.2858 10.4118 20.1955 10.2511 20.0347C10.0903 19.874 10 19.656 10 19.4286V12.5715C10 12.3442 10.0903 12.1262 10.2511 11.9654C10.4118 11.8047 10.6298 11.7144 10.8571 11.7144H18.1429C18.3702 11.7144 18.5882 11.8047 18.7489 11.9654C18.9097 12.1262 19 12.3442 19 12.5715V14.3115L21.3229 12.6529C21.3868 12.6074 21.4621 12.5803 21.5404 12.5746C21.6187 12.5689 21.6971 12.5848 21.7669 12.6207C21.8368 12.6565 21.8955 12.7108 21.9366 12.7777C21.9777 12.8446 21.9996 12.9215 22 13.0001V19.0001C21.9996 19.0786 21.9777 19.1555 21.9366 19.2224C21.8955 19.2893 21.8368 19.3436 21.7669 19.3795C21.6971 19.4153 21.6187 19.4312 21.5404 19.4255C21.4621 19.4199 21.3868 19.3928 21.3229 19.3472L19 17.6886V19.4286C19 19.656 18.9097 19.874 18.7489 20.0347C18.5882 20.1955 18.3702 20.2858 18.1429 20.2858Z"
      fill="white"
    />
    <defs>
      <linearGradient id="paint0_linear_3572_830" x1="16" y1="16" x2="32" y2="16" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2B59E0" />
        <stop offset="0.665272" stopColor="#0EB6D5" stopOpacity="0.733865" />
        <stop offset="0.9999" stopColor="#00E5D0" />
        <stop offset="1" stopColor="#00E5D0" />
        <stop offset="1" stopColor="#00E5D0" stopOpacity="0.6" />
        <stop offset="1" stopColor="#0DBBD5" />
      </linearGradient>
    </defs>
  </svg>
)

export default VideocamGradient
