const isHashString = (path: string) => path.includes('#') && path.includes('=')

const parseHashFromString = (path: string): Record<string, string> => {
  if (!isHashString) {
    return {}
  }
  const hash = path.split('#')[1].replace(/\+/g, ' ')
  const couples = hash.split('&').map((h) => decodeURIComponent(h))

  return couples.map((c) => c.split('=')).reduce((prev, [k, v]) => (v ? { ...prev, [k]: v } : prev), {})
}

const transformToHash = (data: Record<string, string | number | string[] | undefined | null | number[]>) => {
  const hashArray: string[] = []
  Object.entries(data).forEach(([key, value]) => {
    if (value && Array.isArray(value) && value.length) {
      value.forEach((item) => {
        hashArray.push(`${encodeURIComponent(key)}[]=${encodeURIComponent(String(item))}`)
      })
    } else if (value && !Array.isArray(value)) {
      hashArray.push(`${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
    }
  })
  return hashArray.join('&')
}

export { parseHashFromString, isHashString, transformToHash }
