import { useEffect, useState } from 'react'

export default function usePageScrollProgress(): number {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const trackScroll = () => {
      const windowScroll = document.body.scrollTop || document.documentElement.scrollTop
      const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight
      setProgress((windowScroll / scrollHeight) * 100)
    }

    window.addEventListener('scroll', trackScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', trackScroll)
    }
  }, [])

  return progress
}
