import { useEffect } from 'react'
import { useTheme } from '@hermes/web-components'

const getElementOffset = (element: HTMLElement) => {
  const isFixed = element.style.position === 'fixed'

  const positionTop = isFixed ? window.scrollY : element.offsetTop
  const positionLeft = isFixed ? window.scrollX : element.offsetLeft

  return {
    top: positionTop,
    left: positionLeft
  }
}

// NOTE that you need to pass whole object returned from useRef hook in order to track when reference is updated
export default function useStickyElementOnScroll(
  reference: { current?: HTMLElement },
  options?: { isMobileOnly?: boolean; isDesktopOnly: boolean }
) {
  const theme = useTheme()

  useEffect(() => {
    const { current: element } = reference
    const { isDesktopOnly, isMobileOnly } = options || {}
    const {
      breakpoints: { values }
    } = theme
    const isMobileViewPort = window.innerWidth <= values.md + 64
    const changePosition =
      (isMobileOnly && isMobileViewPort) || (isDesktopOnly && !isMobileViewPort) || (!isDesktopOnly && !isMobileOnly)
    if (element && changePosition) {
      const {
        position: originalPositionStyle,
        top: originalTopStyle,
        paddingTop: originalPaddingTopStyle,
        marginTop: originalMarginTopStyle,
        zIndex: originalIndexStyle
      } = element.style
      const elementOffset = getElementOffset(element).top

      const updateElementPosition = () => {
        const offset = isMobileViewPort ? 48 : 64
        if (window.scrollY + offset > elementOffset) {
          element.style.position = 'fixed'
          element.style.top = isMobileViewPort ? '48px' : '64px'
          element.style.paddingTop = '0px'
          element.style.marginTop = '0px'
          element.style.zIndex = '1050'
        } else {
          element.style.position = originalPositionStyle
          element.style.top = originalTopStyle
          element.style.paddingTop = originalPaddingTopStyle
          element.style.marginTop = originalMarginTopStyle
          element.style.zIndex = originalIndexStyle
        }
      }
      window.addEventListener('scroll', updateElementPosition, { passive: true })
      window.addEventListener('resize', updateElementPosition, { passive: true })

      return () => {
        window.removeEventListener('scroll', updateElementPosition)
        window.removeEventListener('resize', updateElementPosition)
      }
    }
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference])
}
