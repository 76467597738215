import { Box } from '@hermes/web-components'
import { FC, useMemo } from 'react'
import useTranslateMessage from '../../../hooks/useTranslateMessage'
import ReviewStatisticRow from './ReviewStatisticRow'
import { getRoundedReviewValue } from '../../../utils/review'
import { ProfileReview } from '../../../types'

interface ReviewCardProps {
  review: ProfileReview
}

const ReviewStatistic: FC<ReviewCardProps> = ({ review }) => {
  const translate = useTranslateMessage()

  const reviewStatisticList = useMemo(
    () => [
      { name: translate('reviews.overallExperience'), rating: getRoundedReviewValue(review.rating.overallExperience) },
      { name: translate('reviews.easy'), rating: getRoundedReviewValue(review.rating.easy) },
      { name: translate('reviews.impact'), rating: getRoundedReviewValue(review.rating.impact) },
      { name: translate('reviews.quality'), rating: getRoundedReviewValue(review.rating.quality) },
      { name: translate('reviews.bedsideManner'), rating: getRoundedReviewValue(review.rating.bedsideManner) },
      { name: translate('reviews.explanation'), rating: getRoundedReviewValue(review.rating.explanation) },
      { name: translate('reviews.cleanliness'), rating: getRoundedReviewValue(review.rating.cleanliness) },
      { name: translate('reviews.friendliness'), rating: getRoundedReviewValue(review.rating.friendliness) },
      { name: translate('reviews.wait_time'), rating: review.rating.waitTime },
      { name: translate('reviews.facilities'), rating: review?.rating.facilities },
      { name: translate('reviews.careAndSupport'), rating: review?.rating.careAndSupport }
    ],
    [review.rating, translate]
  )
  return (
    <Box sx={{ mb: 1, mt: 0.75 }}>
      {reviewStatisticList.map((props) => (
        <ReviewStatisticRow key={props.name} {...props} noSpaceBefore />
      ))}
    </Box>
  )
}

export default ReviewStatistic
