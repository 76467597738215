import { Breadcrumb } from '../../types/breadcrumbs'
import getConfigVariable from '../getConfigVariable'

export default function getBreadcrumbsSchema(breadcrumbs?: Breadcrumb[]) {
  const stage = getConfigVariable('STAGE')
  const suffix = stage === 'production' ? 'www.' : `${stage}.`
  const itemListElement = breadcrumbs?.map((breadcrumb, index) => ({
    '@type': 'ListItem',
    position: index + 1,
    item: {
      '@type': 'WebPage',
      id: `https://${suffix}doctify.com${breadcrumb.url}`,
      name: breadcrumb.label
    }
  }))

  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement
  }
}
