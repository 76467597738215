import React from 'react'
import { SvgIcon } from '../../components/SvgIcon'

type FontSize = 'small' | 'inherit' | 'large' | 'medium'

const Edit = ({ color = 'white', fontSize }: { color?: string; fontSize?: FontSize }) => (
  <SvgIcon
    fontSize={fontSize}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 1H9V2H2V14H14V8H15V15H14H1V14V2V1ZM4 12V9.96306L10.0076 3.95546L12.0445 5.99239L6.03694 12H4ZM11.9631 2L14 4.03694L12.6257 5.41662L10.5888 3.37969L11.9631 2Z"
      fill={color}
    />
  </SvgIcon>
)

export default Edit
