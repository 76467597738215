import { SearchReviewsFilters } from '../providers/SearchReviews/types'
import { isHashString, parseHashFromString } from './hash'
import { TENANTS } from '../constants/global'

type Sort = 'desc' | 'asc' | 'bestRated' | 'worstRated' | null

const getValidSort = (sort: string): Sort => {
  if (sort === 'desc' || sort === 'asc' || sort === 'bestRated' || sort === 'worstRated' || sort === null) {
    return sort
  }
  return 'desc'
}

const getRoundedReviewValue = (reviewValue?: number | string) => {
  const roundValue = 4 // 0.25
  if (!reviewValue || typeof reviewValue !== 'number') {
    return 0
  }
  return Math.floor(reviewValue * roundValue) / roundValue
}

const getHashFromString = (path: string) => {
  const { sort = 'desc', keyword = '', search = '' } = isHashString(path) ? parseHashFromString(path) : {}
  return { sort: getValidSort(sort), keyword, search }
}

const isActualFilters = (routerFilters: string | SearchReviewsFilters, filters: SearchReviewsFilters) => {
  const { sort, keyword, search } = typeof routerFilters === 'string' ? getHashFromString(routerFilters) : routerFilters
  return sort === filters.sort && keyword === filters.keyword && search === filters.search
}

const tenantForChip = (tenant?: string) => {
  if (!tenant) {
    return null
  }

  const objTenant = {
    [TENANTS.AR_UAE]: TENANTS.EN_UAE
  }
  return objTenant[tenant] ?? tenant
}

export { getHashFromString, isActualFilters, getRoundedReviewValue, tenantForChip }
