import { ReactNode } from 'react'
import { Grid, Typography, Rating } from '@hermes/web-components'

const ReviewStatisticRow = ({
  rating,
  name,
  noSpaceBefore = false
}: {
  rating?: number
  name: string | ReactNode
  noSpaceBefore?: boolean
}) => {
  if (!rating) {
    return null
  }
  return (
    <Grid sx={noSpaceBefore ? { pr: 2 } : { px: 2 }} container alignItems="center">
      <Grid item display="flex" alignItems="center" sx={{ mr: 3 }}>
        <Rating readOnly value={rating} sx={{ '& svg': { width: 18, height: 17 }, mr: 1 }} />
        <Typography variant="caption" sx={{ m: 0 }}>
          {rating.toFixed(2)}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">{name}</Typography>
      </Grid>
    </Grid>
  )
}

export default ReviewStatisticRow
