import React from 'react'
import { SvgIcon, SvgIconProps } from '../../components/SvgIcon'

type ArrowRightProps = Omit<SvgIconProps, 'color'> & {
  color?: string
}

const ArrowRight = ({ color, ...props }: ArrowRightProps) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M8 2L6.9425 3.0575L11.1275 7.25H2V8.75H11.1275L6.9425 12.9425L8 14L14 8L8 2Z" fill={color || '#2B59E0'} />
  </SvgIcon>
)

export default ArrowRight
