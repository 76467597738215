import React from 'react'
import type { PropsWithChildren, HTMLAttributes } from 'react'
import { InView } from 'react-intersection-observer'
import { Box } from '../Box'

export type LazyProps = PropsWithChildren<{
  ssrOnly?: boolean
  sx?: Record<string, any>
  margin?: number
  onChange?: (val: boolean) => void
}> &
  HTMLAttributes<HTMLDivElement>

function Lazy({ children, ssrOnly, sx, margin, onChange, ...rest }: LazyProps) {
  return (
    <InView skip={ssrOnly} initialInView={ssrOnly} triggerOnce onChange={onChange} rootMargin={`${margin || 40}px`}>
      {({ inView, ref }) => (
        <Box ref={ref} sx={sx} {...rest}>
          {inView && children}
        </Box>
      )}
    </InView>
  )
}

Lazy.defaultProps = {
  ssrOnly: false,
  sx: {}
}

export default Lazy
