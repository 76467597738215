import React from 'react'
import { SvgIcon, SvgIconProps } from '../../components/SvgIcon'

type VerifiedIconProps = SvgIconProps & {
  color?: string
}

// todo: color shouldn't be passed to SvgIcon?
// eslint-disable-next-line no-unused-vars
const VerifiedIcon = ({ color, fill, width, height, ...props }: VerifiedIconProps) => (
  <SvgIcon width={width || '18'} height={height || '22'} viewBox="0 0 18 22" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 4L9 0L18 4V10C18 15.55 14.16 20.74 9 22C3.84 20.74 0 15.55 0 10V4ZM3 12L4.41 10.59L7 13.17L13.59 6.58L15 8L7 16L3 12Z"
      fill={fill || 'white'}
    />
  </SvgIcon>
)

export default VerifiedIcon
