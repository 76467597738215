import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const ReplyIcon = (props: SvgIconProps) => (
  <SvgIcon width="14" height="12" viewBox="0 0 14 12" {...props}>
    <path
      d="M8 8.31028L8 11.5L14 5.87945L8 0.335967L8 3.60543C4.11111 3.60543 1.94444 2.79101 5.16875e-08 -2.70245e-07C0.777777 3.98715 2.55556 7.51284 8 8.31028Z"
      fill="#5C6B88"
    />
  </SvgIcon>
)

export default ReplyIcon
