import type { PropsWithChildren, RefObject } from 'react'
import { SxProps, styled, useTheme } from '@hermes/web-components'
import ContentTopContainer from '../ContentTopContainer'

const SectionWrapperStyled = styled('section', {
  shouldForwardProp: (prop: string) => prop !== 'sx'
  // @ts-ignore
})<{ sx?: Record<string, any> }>(({ sx }) => sx || {})

const SectionWrapper = ({
  sx,
  children,
  id,
  ignoreContainer,
  withPadding = false,
  containerRef
}: PropsWithChildren<{
  sx?: SxProps
  id?: string
  ignoreContainer?: boolean
  withPadding?: boolean
  containerRef?: RefObject<HTMLDivElement>
}>) => {
  const theme = useTheme()
  return (
    <SectionWrapperStyled ref={containerRef} sx={{ py: withPadding ? theme.spacing(5) : undefined, ...sx }} id={id}>
      {ignoreContainer ? children : <ContentTopContainer maxWidth="xl">{children}</ContentTopContainer>}
    </SectionWrapperStyled>
  )
}

export default SectionWrapper
