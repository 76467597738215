import { useMemo, useContext } from 'react'
import {
  Typography,
  Grid,
  LocationOnIcon,
  VerifiedIcon,
  Box,
  UnverifiedIcon,
  useTheme,
  MedicalServicesIcon,
  VideocamGradient,
  Edit,
  Button,
  Hidden,
  PlayCircleIcon,
  Chip,
  ConnectionsBadgeIcon,
  WhiteTooltip,
  useMediaQuery
} from '@hermes/web-components'
import { useRouter } from 'next/router'
import { formatNumberWithPlus } from '@hermes/web-components/src/utils/formatters'
import { useKeywordsMapper, useTopLevelKeywords } from '../../../hooks/useKeywordsMapper'
import { AppDataContext } from '../../../providers/AppData'
import useTranslateMessage from '../../../hooks/useTranslateMessage'
import { getYearsDiff } from '../../../utils/date'
import { handleAddCookieCTA } from '../../../utils/cookie'
import {
  getAddressFromPractices,
  transformAddressToShortString,
  transformAddressToString
} from '../../../utils/address'
import {
  CONNECTION_COUNT_TRUST_BADGE_PRACTICE,
  CONNECTION_COUNT_TRUST_BADGE_SPECIALIST,
  doctifyProviderDomain,
  endpoint,
  pathMap
} from '../../../constants/profile'
import { ProfileIntroProps } from '../../../types'
import getLanguageFromLocale from '../../../utils/getLanguageFromLocale'
import {
  TextBox,
  StyledPlayVideoButton,
  StyledTypographyAvatar,
  LocationText,
  Title,
  TopKeywordText,
  StyledAvatar,
  StyledAvatarContainer
} from './styles'
import getImageWithWWW from '../../../utils/getImageWithWWW'

const claimYourProfile = 'claim-your-profile'

const Badge = ({ icon, title, color }: { icon: JSX.Element; title: string; color?: string }) => (
  <TextBox color={color || 'common.white'} height={'32px'}>
    {icon}
    <Typography
      variant="subtitle2"
      color={color || 'system.navy'}
      textTransform="uppercase"
      lineHeight="initial"
      ml={1}
      mr={2}
      my={0.5}
      fontWeight={500}
      display="flex"
      component="p"
      alignItems="center"
    >
      {title}
    </Typography>
  </TextBox>
)

export const getInitialsFromFullName = (fullName: string): string => {
  const splittedName = fullName.split(' ')
  const firstName = splittedName[0]
  const lastName = splittedName[splittedName.length - 1]
  return firstName[0] + lastName[0]
}

// todo: fix complexity
// eslint-disable-next-line complexity
function ProfileIntro({
  id,
  name,
  suffix,
  plan,
  slug,
  image,
  keywords,
  yearsOfExperience,
  address,
  isPractice: isClinic,
  practices,
  isUnclaimed = false,
  isEntry = false,
  videoConsultation,
  introVideoAvailable = false,
  setVideoPlayerVisibility = () => null,
  profileAnalytics,
  connectionsCount = 0
}: ProfileIntroProps) {
  const theme = useTheme()
  const { tenantSettings, language, locale, isRtl } = useContext(AppDataContext)
  const translate = useTranslateMessage()
  const router = useRouter()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const imageWithWWW = getImageWithWWW(image)
  const formattedCount = useMemo(
    () => (isClinic ? formatNumberWithPlus(connectionsCount) : connectionsCount),
    [isClinic, connectionsCount]
  )

  const isEntryOrUnclaimed = useMemo(() => isEntry || isUnclaimed, [isEntry, isUnclaimed])

  const showTrustBadge = useMemo(() => {
    if (isClinic) {
      return connectionsCount >= CONNECTION_COUNT_TRUST_BADGE_PRACTICE
    }

    return connectionsCount >= CONNECTION_COUNT_TRUST_BADGE_SPECIALIST
  }, [connectionsCount, isClinic])

  const trustBadgeTooltipText = useMemo(
    () =>
      translate(`profile.intro.trustedNetwork.tooltip${isClinic ? '-practice' : ''}`, {
        connectionsCount: String(formattedCount)
      }),
    [formattedCount, isClinic, translate]
  )

  const isMarketingEmail = router.query?.utm_source === 'marketingemail'

  const CTAUrl = isMarketingEmail
    ? `/${locale}${router.asPath.split('?')[0]}/${
        locale === 'de' || locale === 'de-at' ? 'beanspruchen-sie-ihr-profil' : claimYourProfile
      }`
    : doctifyProviderDomain + pathMap[locale] + endpoint

  const initials = getInitialsFromFullName(name)
  const topLevelKeywords = useTopLevelKeywords(keywords || [])
  const { specialties, subSpecialties } = useKeywordsMapper(keywords || [], isClinic)
  const lang = getLanguageFromLocale(locale)
  const userTitle = useMemo(() => {
    let ut = name
    if (suffix) {
      ut = `${suffix} ${ut}`
    }
    return ut
  }, [name, suffix])

  const yearsOfExperienceValue = useMemo(() => getYearsDiff(yearsOfExperience || ''), [yearsOfExperience])

  const transformedAddress = useMemo(() => {
    const addressToTransform = isClinic ? address : getAddressFromPractices(practices)
    if (!addressToTransform) {
      return ''
    }

    return isClinic
      ? transformAddressToString(addressToTransform, language)
      : transformAddressToShortString(addressToTransform, language)
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, practices, isClinic])

  const medicalServicesIcon = <MedicalServicesIcon width="32px" height="32px" />

  const handleOpenVideoPlayer = () => {
    if (introVideoAvailable) {
      if (window) {
        window.dataLayer.push({
          event: 'prf_pg_prf_vid_click',
          cont_prf_name: userTitle,
          ...profileAnalytics
        })
      }
      setVideoPlayerVisibility(true)
    }
  }

  const verifiedIcon = (
    <Box
      borderRadius={5}
      bgcolor="system.aqua400"
      p={0.5}
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="32px"
    >
      <VerifiedIcon sx={{ height: 20, width: 20 }} color="inherit" fill="#051851" />
    </Box>
  )
  const unverifiedIcon = (
    <Box
      borderRadius={5}
      bgcolor="system.coral200"
      p={0.5}
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="32px"
    >
      <UnverifiedIcon sx={{ height: 32, width: 32 }} color="inherit" fill="#051851" />
    </Box>
  )

  const profileDetails = (
    <Grid container mt="24px" gap={2} direction="column">
      <Grid container display="flex" gap={2} flexDirection="row" wrap="nowrap">
        {isUnclaimed ? (
          <Badge icon={unverifiedIcon} title={translate('unverivied_profile')} />
        ) : (
          <Badge icon={verifiedIcon} title={translate('verified_profile')} />
        )}
        {!isClinic && !!yearsOfExperienceValue && (
          <Badge
            icon={medicalServicesIcon}
            title={translate('years_of_experience', { years: yearsOfExperienceValue })}
          />
        )}
      </Grid>
      {showTrustBadge && (
        <Grid container alignItems="center" gap="6px" my="16px">
          <WhiteTooltip
            isRtl={isRtl}
            title={trustBadgeTooltipText}
            componentsProps={{
              tooltip: {
                sx: {
                  ...(isMobile
                    ? {
                        '&:before': {
                          left: '12% !important'
                        },
                        '&:after': {
                          left: '12% !important'
                        }
                      }
                    : {}),
                  height: 'auto !important',
                  padding: '15px 10px !important'
                }
              }
            }}
          >
            <Box>
              <ConnectionsBadgeIcon withCheck={false} count={formattedCount} width="16px" height="16px" />
            </Box>
          </WhiteTooltip>
          <Typography variant="body2" color="text.darkNavy">
            {translate('profile.intro.trustedNetwork')}
          </Typography>
        </Grid>
      )}
      {isClinic && isUnclaimed && (
        <Grid item>
          <Box>
            <Typography color="primary.main" variant="body2">
              {translate('are_you_manager', { name, skipBold: true, defaultMessage: `Are you ${name} manager?` })}
            </Typography>
          </Box>
          <Button
            variant="contained"
            size="small"
            sx={{ mt: 1, px: 1, py: 0.5 }}
            href={CTAUrl}
            onClick={() => handleAddCookieCTA({ id, slug, type: plan })}
          >
            <Typography variant="caption" mr={1} fontWeight={500}>
              {translate(isMarketingEmail ? claimYourProfile : 'manage_your_profile')}
            </Typography>
            <Edit fontSize="inherit" />
          </Button>
        </Grid>
      )}
      {isUnclaimed && !isClinic && (
        <Grid item>
          <Box borderRadius={1} p={{ lg: theme.spacing(0.5, 1) }} bgcolor="common.white" display="inline-block">
            <Typography variant="body2" display="inline" mr={2} lineHeight={2}>
              {translate('are_you', { name }, { bold: { fontWeight: 600 } })}
            </Typography>
            <Button
              variant="contained"
              sx={{ padding: theme.spacing(0.5, 1.5) }}
              href={CTAUrl}
              onClick={() => handleAddCookieCTA({ id, slug, type: plan })}
            >
              <Typography color="common.white" fontWeight={500} variant="caption">
                {translate(isMarketingEmail ? claimYourProfile : 'manage_your_profile')}
              </Typography>
            </Button>
          </Box>
        </Grid>
      )}
      <Grid item>
        {!isClinic && videoConsultation && (
          <Badge icon={<VideocamGradient />} title={`${translate('video')} ${translate('consultation')}`} />
        )}
      </Grid>
    </Grid>
  )

  return (
    <Grid container sx={{ pb: { xs: '16px', sm: '32px' } }}>
      <StyledAvatarContainer item maxHeight="min-content" position="relative" mr={2}>
        <Button
          onClick={handleOpenVideoPlayer}
          variant="ghost"
          sx={{
            padding: 0
          }}
        >
          <StyledAvatar
            width={92}
            height={92}
            alt={userTitle}
            src={!isEntryOrUnclaimed ? imageWithWWW : ''}
            preload
            sx={{
              backgroundColor: !isEntryOrUnclaimed && image ? 'transparent' : 'blue.200'
            }}
          >
            <StyledTypographyAvatar>{initials}</StyledTypographyAvatar>
          </StyledAvatar>
        </Button>
        {introVideoAvailable && (
          <StyledPlayVideoButton
            aria-label={translate('media_center.introduction_video')}
            variant="ghost"
            onClick={handleOpenVideoPlayer}
          >
            <PlayCircleIcon
              sx={{
                width: '35px',
                height: '35px'
              }}
            />
          </StyledPlayVideoButton>
        )}
      </StyledAvatarContainer>
      <Grid container xs={7.4} direction="column">
        <Title variant="h2" component="h1" fontWeight="700">
          {userTitle}
        </Title>
        <Grid
          container
          mt={1}
          sx={{
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
              alignItems: 'start',
              flexDirection: 'column'
            }
          }}
        >
          {!isClinic && !!topLevelKeywords && <TopKeywordText color="text.darkNavy">{topLevelKeywords}</TopKeywordText>}
          {isClinic && !!subSpecialties?.length && (
            <Grid container alignItems="center" gap="12px" sx={{ pt: { xs: 2 }, pl: { xs: 2.5 } }}>
              <Typography variant="subtitle1" fontWeight={500}>
                {specialties[0]?.name[lang]}
              </Typography>
              <Chip
                sx={{
                  fontSize: '10.53px',
                  fontWeight: 500,
                  lineHeight: '15.795px',
                  color: 'text.dark',
                  padding: '1px 6px',
                  span: {
                    padding: 0
                  }
                }}
                label={`+${subSpecialties.length}`}
                variant="blue"
              />
            </Grid>
          )}
          <Grid
            item
            display="flex"
            sx={{
              [theme.breakpoints.down('sm')]: {
                ...(!isClinic ? { paddingTop: '0 !important' } : {})
              }
            }}
          >
            <Hidden smDown>
              <LocationOnIcon
                sx={{
                  ml: Number(!isClinic),
                  width: 16,
                  height: 16,
                  marginRight: '4px',
                  path: { fill: theme.palette.text.darkNavy }
                }}
              />
            </Hidden>
            <Hidden smUp>
              {isClinic && (
                <LocationOnIcon
                  sx={{
                    width: 16,
                    height: 16,
                    marginRight: '4px',
                    path: { fill: theme.palette.text.darkNavy }
                  }}
                />
              )}
            </Hidden>
            <LocationText color="text.darkNavy" textTransform="capitalize">
              {transformedAddress || tenantSettings?.defaultLocations?.[0]?.country}
            </LocationText>
          </Grid>
        </Grid>
        <Hidden smDown>{profileDetails}</Hidden>
      </Grid>
      <Hidden smUp implementation="css">
        {profileDetails}
      </Hidden>
    </Grid>
  )
}

export default ProfileIntro
